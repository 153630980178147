import { Injectable } from '@angular/core';

@Injectable()
export class userService {
  data: any;

  constructor() { }

  setData(data: any) {
    window.localStorage.setItem('userService', JSON.stringify(
      data
    ));
    this.data = data;
  }

  getData() {
    this.data = JSON.parse(window.localStorage.getItem('userService'));
    return this.data;
  }

  clearData() {
    window.localStorage.removeItem('userService');
    this.data = null;
  }
}