import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@Component({
  selector: 'app-create-sub-project',
  templateUrl: './create-sub-project.component.html',
  styleUrls: ['./create-sub-project.component.scss'],
})
export class CreateSubProjectComponent implements OnInit {
  
  @Input() project: any;
  @Output() back = new EventEmitter<any>();
  user: any = {};
  companies: any = {};
  types: any = {};
  categories: any = {};
  newProject: any = {};

  constructor(
    public userService: userService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { }

  getCompanies() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCompanies')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companies = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
        }
      );
    
  }

  getTypes() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getTypes')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.types = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project type data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project type data' });
        }
      );
    
  }

  getCategories() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCategories')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.categories = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting category data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting category data' });
        }
      );
    
  }

  submit() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createSubProject')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      project: this.project,
      data: this.newProject
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
            this.toastService.showToast({ header: 'Success', message: 'Project has been created' });
            this.back.emit();
            this.newProject = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating project data' });
        }
      );

  }

  returnZero() {
    return 0
  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.getCompanies();
    this.getTypes();
    this.getCategories();
  }

}
