import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';
import { dataService } from '../../services/data.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss'],
})
export class EditCompanyComponent implements OnInit {
  @Input() company: any;
  @Output() back = new EventEmitter<any>();

  user: any = {};
  sectors: any = {};
  areas: any = {};
  users: any = {};
  uon: any = {};
  mst: any = {};
  sources: any = {};
  teams: any = {};
  status: any = {};
  newContact: any = {};
  newLog: any = {};
  logTypes: any = {};
  ineligibleStatus: any = {};
  config: any;
  accordion: any = 0;
  companyProjects: any;

  constructor(
    public userService: userService, 
    public dataService: dataService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { 

  }

  submit() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'updateCompany')

    const body = {
      id: this.user.id,
      cid: this.user.cid,
      data: this.company
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
            this.toastService.showToast({ header: 'Success', message: 'Company has been updated' });
            this.back.emit();
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating company data' });
        }
      );
  }

  createContact() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createCompanyContact')

    const body = {
      id: this.user.id,
      cid: this.company.id,
      name: this.newContact.name,
      email: this.newContact.email,
      phone: this.newContact.phone,
      role: this.newContact.role,
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.toastService.showToast({ header: 'Success', message: 'Company contact has been created' });
            this.company = response.body.dataArray;
            this.newContact = '';
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating company contact' });
        }
      );
  }

  createActivity() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createActivityLog')

    const body = {
      id: this.user.id,
      cid: this.company.id,
      type: this.newLog.type,
      date: this.newLog.date,
      duration: this.newLog.duration,
      totalPreviousDuration: this.company.logHours,
      companyName: this.company.name,
      notes: this.newLog.notes,
      recipients: this.newLog.recipients,
      attachment: this.newLog.attachment
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.toastService.showToast({ header: 'Success', message: 'Company log has been created' });
            this.company = response.body.dataArray;
            this.calcLogHours();
            this.newLog = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating company log' });
        }
      );
  }

  getSectors() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getSectors')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.sectors = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting sector data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting sector data' });
        }
      );

  }

  getAreas() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getAreas')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.areas = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
        }
      );

  }

  getUsers() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getUsers')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      pid: 3
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.users = response.body.dataArray;
            this.uon = response.body.uon;
            this.mst = response.body.mst;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
        }
      );

  }

  getSources() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getSources')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.sources = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting source data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting source data' });
        }
      );

  }

  getTeams() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getTeams')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.teams = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting team data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting team data' });
        }
      );

  }

  getStatus() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCompanyStatus')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.status = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting status data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting status data' });
        }
      );

  }

  getIneligibleStatus() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getIneligibleStatus')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.ineligibleStatus = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting status data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting status data' });
        }
      );

  }

  getLogTypes() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getLogTypes')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.logTypes = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting log type data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting log type data' });
        }
      );

  }

  addPhoto(fileInput: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadFile(reader.result, fileInput.target.files[0].type)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadFile(y: any, z: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Photo uploaded successfully' });
            this.company.photo = response.body.url;
            this.company.photoUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  deletePhoto() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteCompanyImage')

    const body = {
      id: this.user.id,
      company: this.company
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.response == 204) {
            this.company.photo = '';
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your image' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your image' });
        }
      );
  }

  calcLogHours() {
    this.company.logHours = 0
    if (this.company.logs) {
      for (const log of this.company.logs) {
        this.company.logHours = this.company.logHours + (log.duration.substring(0, 2) * 60 + parseInt(log.duration.substring(3, 5)));
      }
      this.company.logHours = (this.company.logHours / 60);
    }
  }
  
  addExistingLogAttachment(fileInput: any, x: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadExistingLogAttachment(reader.result, fileInput.target.files[0].type, x)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadExistingLogAttachment(y: any, z: any, x: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Attachment uploaded successfully' });
            this.company.logs[x].attachment = response.body.url;
            this.company.logs[x].attachmentUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  addCompanyAttachment(fileInput: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadCompanyAttachment(reader.result, fileInput.target.files[0].type)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);
  }

  uploadCompanyAttachment(y: any, z: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Company attachment uploaded successfully' });
            this.company.attachment = response.body.url;
            this.company.attachmentUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  deleteCompanyAttachment() {
    this.company.attachment = '';
  }

  addLogAttachment(fileInput: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadLogAttachment(reader.result, fileInput.target.files[0].type)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadLogAttachment(y: any, z: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Attachment uploaded successfully' });
            this.newLog.attachment = response.body.url;
            this.newLog.attachmentUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  openFile(x: any) {
    window.open(x, '_blank');
  }

  deleteLog(x: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteActivityLog')

    const body = {
      id: this.user.id,
      cid: this.company.id,
      log: x
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Log has been deleted' });
            this.company.logs = response.body.dataArray;
            this.calcLogHours();
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting log' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting log' });
        }
      );
  }

  updateLog(log: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'updateActivityLog')

    const body = {
      id: this.user.id,
      log: log
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Log has been updated' });
            this.calcLogHours();
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating log' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating log' });
        }
      );
  }

  deleteAttachment(log: any, attachment: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteActivityLogAttachment')

    const body = {
      id: this.user.id,
      log: this.company.logs[log].id,
      attachment: attachment
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.response == 204) {
            this.company.logs[log].attachment = '';
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your attachment' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your attachment' });
        }
      );
  }

  toggleAccordion(x: any) {
    if (x == this.accordion) {
      this.accordion = 0;
    }
    else {
      this.accordion = x;
    }
  }

  getCompanyProjects() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCompanyProjects')

    const body = {
      id: this.user.id,
      cid: this.company.id
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companyProjects = response.body.dataArray;
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company project data' });
        }
      );
  }

  returnZero() {
    return 0
  }

  goCompanyProject(project: any) {
    this.dataService.setData('customerProject', project)
    this.router.navigate(['dashboard/projects']);
  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.config = globals;
    this.company.photoUrl = globals.cdn + this.company.photo;
    this.calcLogHours();
    this.getSectors();
    this.getAreas();
    this.getUsers();
    this.getSources();
    this.getTeams();
    this.getStatus();
    this.getIneligibleStatus();
    this.getLogTypes();
    this.getCompanyProjects();
  }

}