import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit {

  @Output() back = new EventEmitter<any>();
  newCompany: any = {};
  companyResults: any;
  user: any = {};
  sectors: any = {};
  areas: any = {};
  users: any = {};
  sources: any = {};
  teams: any = {};

  constructor(
    public userService: userService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { 

  }

  submit() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createCompany')

    const body = {
      id: this.user.id,
      cid: this.user.cid,
      data: this.newCompany
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
            this.toastService.showToast({ header: 'Success', message: 'Company has been created' });
            this.back.emit();
            this.newCompany = {};
          }
          else if (response.body.response == 409) {
            this.toastService.showToast({ header: 'Error', message: 'Company may already exist' });
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating company data' });
        }
      );

  }

  getSectors() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getSectors')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.sectors = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting sector data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting sector data' });
        }
      );

  }

  getAreas() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getAreas')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.areas = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
        }
      );

  }

  getUsers() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getUsers')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      pid: 3
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.users = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
        }
      );

  }

  getSources() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getSources')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.sources = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting source data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting source data' });
        }
      );

  }

  getTeams() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getTeams')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.teams = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting team data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting team data' });
        }
      );

  }

  checkCompanyName() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'checkCompanyName')

    const body = {
      id: this.user.id,
      data: this.newCompany
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companyResults = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something checking name against companies house' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something checking name against companies house' });
        }
      );

  }

  selectCompany(company: any) {
    this.newCompany.name = company.title;
    this.newCompany.address = company.address.premises + ' ' + company.address.address_line_1 + ', ' + company.address.locality + ', ' + company.address.region + ', ' + company.address.country;
    this.newCompany.postcode = company.address.postal_code;
    this.newCompany.number = company.company_number;
    this.companyResults = '';
  }

  checkCompanyNumber() {

    if (this.newCompany.number.length == 8) {
      const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'checkCompanyNumber')

      const body = {
        id: this.user.id,
        data: this.newCompany
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            if (response.body.success == true && response.body.dataArray) {
              this.newCompany.name = response.body.dataArray.company_name;
              this.newCompany.address = response.body.dataArray.registered_office_address.address_line_1 + ', ' + response.body.dataArray.registered_office_address.locality + ', ' + response.body.dataArray.registered_office_address.region + ', ' + response.body.dataArray.registered_office_address.country;
              this.newCompany.postcode = response.body.dataArray.registered_office_address.postal_code;
            }
            else {
              this.toastService.showToast({ header: 'Error', message: 'Oops, something checking number against companies house' });
            }
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something checking numbers against companies house' });
          }
        );
      }
  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.getSectors();
    this.getAreas();
    this.getUsers();
    this.getSources();
    this.getTeams();
  }

}
