import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { userService } from './services/user.service';
import { dataService } from './services/data.service';
import { ToastService } from './services/toast.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger,state,style,animate,transition }from '@angular/animations';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// import { saveAs } from 'file-saver';
// import { FileSaverModule } from 'ngx-filesaver';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { NgxEchartsModule } from 'ngx-echarts';

import { CreateCompanyComponent } from 'src/app/components/create-company/create-company.component';
import { EditCompanyComponent } from 'src/app/components/edit-company/edit-company.component';
import { CreateProjectComponent } from 'src/app/components/create-project/create-project.component';
import { CreateSubProjectComponent } from 'src/app/components/create-sub-project/create-sub-project.component';
import { EditSubProjectComponent } from 'src/app/components/edit-sub-project/edit-sub-project.component';
import { CreateUserComponent } from 'src/app/components/create-user/create-user.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
              BrowserModule, 
              BrowserAnimationsModule,
              FontAwesomeModule,
              IonicModule.forRoot(), 
              AppRoutingModule, 
              CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory,
              }),
              HttpClientModule,
              NgxEchartsModule.forRoot({
              echarts: () => import('echarts')
            })
          ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    userService,
    dataService,
    ToastService,
    CreateCompanyComponent,
    EditCompanyComponent,
    CreateProjectComponent,
    CreateSubProjectComponent,
    EditSubProjectComponent,
    CreateUserComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
  ) {
		library.addIconPacks(fas, fab, far);
	}
}