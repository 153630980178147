import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@Component({
  selector: 'app-edit-sub-project',
  templateUrl: './edit-sub-project.component.html',
  styleUrls: ['./edit-sub-project.component.scss'],
})
export class EditSubProjectComponent implements OnInit {
  
  @Input() project: any;
  @Output() back = new EventEmitter<any>();
  config: any = {};
  user: any = {};
  users: any = {};
  uon: any = {};
  mst: any = {};
  companies: any = {};
  types: any = {};
  status: any = {};
  currentStatus: any = {};
  categories: any = {};
  categoriesTwo: any = {};
  newLog: any = {};
  projectChanges: any;
  newClaim: any = {};
  claimPercentage: any = 0;
  claimTotal: any = 0;
  grantClaims: any = {};
  claimFrequency: any = [{id: 0, name: 'Unset'},{id: 1, name: 'Monthly'},{id: 2, name: 'Quarterly'}];
  logTypes: any = [{
    id: 1,
    name: 'Time Extension'
  },
  {
    id: 2,
    name: 'Value Increase'
  }, {
    id: 3,
    name: 'Under Spend'
  },
  , {
    id: 4,
    name: 'Spend Reallocation >10%'
  }];
  grantStatus: any = [{
    id: 1,
    name: 'Received'
  },
  {
    id: 2,
    name: 'In progress'
  },
  {
    id: 6,
    name: 'Awaiting Payment'
  },
  {
    id: 3,
    name: 'Paid'
  },
  {
    id: 4,
    name: 'Claimed'
  },
  {
    id: 5,
    name: 'Nil Claim'
  }]

  constructor(
    public userService: userService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { }

  getCompanies() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCompanies')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companies = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
        }
      );
    
  }

  getTypes() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getTypes')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.types = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project type data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project type data' });
        }
      );
    
  }

  getStatus() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getProjectStatus')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.status = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project status data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project status data' });
        }
      );
    
  }

  getCurrentStatus() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getProjectCurrentStatus')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.currentStatus = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project status data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting project status data' });
        }
      );
    
  }

  getCategories() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCategories')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.categories = response.body.dataArray;
            this.categoriesTwo = response.body.two;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting category data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting category data' });
        }
      );
    
  }

  getUsers() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getUsers')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      pid: 3
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.users = response.body.dataArray;
            this.uon = response.body.uon;
            this.mst = response.body.mst;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting user data' });
        }
      );

  }

  update() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'updateSubProject')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      data: this.project
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
            this.toastService.showToast({ header: 'Success', message: 'Project has been updated' });
            this.back.emit();
            this.project = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating project data' });
        }
      );

  }

  addProjectRequest() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'addProjectRequest')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      projectName: this.project.name,
      reason: this.newLog.reason,
      description: this.newLog.description,
      value: this.newLog.value,
      end_date: this.newLog.end_date,
      attachment: this.newLog.attachment
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.toastService.showToast({ header: 'Success', message: 'Change request has been added' });
            this.project = response.body.dataArray;
            this.newLog = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating change request' });
        }
      );
  }

  deleteLog(x: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteProjectRequest')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      log: x
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Change request has been deleted' });
            this.project = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting change request' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting change request' });
        }
      );
  }

  approveLog(x: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'approveProjectRequest')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      currentValue: this.project.value,
      currentDate: this.project.end_date,
      log: x
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Change request has been approved' });
            this.project = response.body.dataArray;
            this.getProjectChanges();
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong approving change request' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong approving change request' });
        }
      );
  }

  getProjectChanges() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getProjectChanges')

    const body = {
      id: this.user.id,
      pid: this.project.id
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.projectChanges = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong fetching your project changes' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong fetching your project changes' });
        }
      );
  }

  updateLog(x: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'updateProjectRequest')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      log: x
    }

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Change request has been updated' });
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating change request' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating change request' });
        }
      );
  }

  addLogAttachment(fileInput: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadLogAttachment(reader.result, fileInput.target.files[0].type)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadLogAttachment(y: any, z: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Attachment uploaded successfully' });
            this.newLog.attachment = response.body.url;
            this.newLog.attachmentUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  addExistingLogAttachment(fileInput: any, x: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadExistingLogAttachment(reader.result, fileInput.target.files[0].type, x)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadExistingLogAttachment(y: any, z: any, x: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Attachment uploaded successfully' });
            this.project.logs[x].attachment = response.body.url;
            this.project.logs[x].attachmentUrl = globals.cdn + response.body.url;
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  deleteAttachment(log: any, attachment: any) {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteProjectRequestAttachment')

    const body = {
      id: this.user.id,
      log: this.project.logs[log].id,
      attachment: attachment
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.response == 204) {
            this.project.logs[log].attachment = '';
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your attachment' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your attachment' });
        }
      );
  }

  openFile(x: any) {
    window.open(x, '_blank');
  }

  addClaim() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'addGrantClaim')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      month: this.newClaim.month,
      value: this.newClaim.value
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Grant claim has been added' });
            this.getGrantClaims();
            this.newClaim = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong adding your grant claim' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong adding your grant claim' });
        }
      );
  }

  getGrantClaims() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getGrantClaims')

    const body = {
      id: this.user.id,
      pid: this.project.id,
      month: this.newClaim.month,
      value: this.newClaim.value
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.grantClaims = response.body.dataArray;
            if (this.grantClaims) {
              for (const log of this.grantClaims) {
                this.claimTotal = this.claimTotal + parseFloat(log.value);
              }
              this.claimPercentage = (this.claimTotal / parseFloat(this.project.value)) * 100;
            }
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong fetching your grant claims' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong fetching your grant claims' });
        }
      );
  }

  updateGrant(x: any) {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'updateGrantClaim')

    const body = {
      id: this.user.id,
      claim: this.grantClaims[x]
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Grant claim status updated' });
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating your grant claim status' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating your grant claim status' });
        }
      );
  }

  deleteGrant(x: any) {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'deleteGrantClaim')

    const body = {
      id: this.user.id,
      claim: this.grantClaims[x]
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Grant claim deleted' });
            this.getGrantClaims();
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your grant claim' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong deleting your grant claim' });
        }
      );
  }

  changeGrantFrequeny() {
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'changeGrantFrequency')

    const body = {
      id: this.user.id,
      sid: this.user.sid,
      pid: this.project.id,
      frequency: this.project.frequency,
      startDate: this.project.start_date,
      endDate: this.project.end_date
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true) {
            this.toastService.showToast({ header: 'Success', message: 'Grant claim frequency has been updated' });
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating grant claim frequency' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong updating grant claim frequency' });
        }
      );
  }

  calcEDRF() {
    var total = parseFloat(this.project.value) * (parseFloat(this.project.intervention_rate) / 100);
    if (isNaN(total)) {
      return '';
    }
    else {
      return (total).toLocaleString('en-US', {
        style: 'currency',
        currency: 'GBP',
      });
    }
  }

  calcSMEMatch () {
    var total = parseFloat(this.project.value) * (1 - (parseFloat(this.project.intervention_rate) / 100));
    if (isNaN(total)) {
      return '';
    }
    else {
      return total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'GBP',
      });
    }
  }

  addPublicSlide(fileInput: any, x: any) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadPublicSlide(reader.result, fileInput.target.files[0].type, x)
      var name = fileInput.target.files[0].name;
    }
    const blob = new Blob(fileInput.target.files, { type: fileInput.target.files[0].type });
    reader.readAsDataURL(blob);

  }

  uploadPublicSlide(y: any, z: any, x: any) {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', this.user.token)
        .set('apiFunctionName', 'uploadFile')

      const body = {
        id: this.user.id,
        file: y,
        type: z
      }

      this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .subscribe(
          (response: any) => {
            this.toastService.showToast({ header: 'Success', message: 'Attachment uploaded successfully' });
            this.project.public_slide_attachment = response.body.url;
            this.update();
          },
          (error: any) => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          }
        );
  }

  returnZero() {
    return 0
  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.config = globals;
    this.getProjectChanges();
    this.getGrantClaims();
    this.getCompanies();
    this.getTypes();
    this.getCategories();
    this.getStatus();
    this.getCurrentStatus();
    this.getUsers();
    this.project.previousStatus = this.project.status;
  }

}