import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {

  @Input() team: any;
  @Output() back = new EventEmitter<any>();
  newUser: any = {};
  user: any = {};
  companies: any = {};
  permissions: any = {};
  areas: any = {};
  

  constructor(
    public userService: userService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { 

  }

  submit() {

    if (window.location.hostname == 'aup.my-runway.co.uk') {
      var url = 'aup';
    } 
    else {                
      var url = 'www';
    }
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createUser')

    const body = {
      id: this.user.id,
      cid: this.user.cid,
      sid: this.user.sid,
      team: this.team,
      user: this.newUser,
      url: url
    };

    if (this.newUser.password == this.newUser.confirm_password) {
      if (this.newUser.password.length > 7) {
        this.http
          .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
          .subscribe(
            (response: any) => {
              if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
                this.toastService.showToast({ header: 'Success', message: 'User has been created' });
                this.back.emit();
                this.newUser = {};
              }
              else {
                this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
              }
            },
            (error: any) => {
              this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating user data' });
            }
          );
      }
      else {
        this.toastService.showToast({ header: 'Error', message: 'Oops, password must be eight or more characters' })
      }
    }
    else {
      this.toastService.showToast({ header: 'Error', message: 'Oops, passwords do not match' })
    }

  }

  getCompanies() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getAllCompanies')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companies = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
        }
      );
    
  }

  getPermissions() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getPermissions')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.permissions = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting permission data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting permission data' });
        }
      );
    
  }

  getAreas() {

    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getAreas')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.areas = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting area data' });
        }
      );

  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.getCompanies();
    this.getPermissions();
    this.getAreas();
  }

}