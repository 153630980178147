import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { userService } from '../../services/user.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  
  @Output() back = new EventEmitter<any>();
  user: any = {};
  companies: any = {};
  newProject: any = {};

  constructor(
    public userService: userService, 
    private router: Router,
    private toastService: ToastService,
    private http: HttpClient
  ) { }

  getCompanies() {

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'getCompanies')

    const body = this.user;

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && response.body.dataArray) {
            this.companies = response.body.dataArray;
          }
          else {
            this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong getting company data' });
        }
      );
    
  }

  submit() {
    
    const header = new HttpHeaders()
    .set('Content-Type', 'text/plain')

    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', this.user.token)
      .set('apiFunctionName', 'createProject')

    const body = {
      id: this.user.id,
      cid: this.user.cid,
      sid: this.user.sid,
      data: this.newProject
    };

    this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .subscribe(
        (response: any) => {
          if (response.body.success == true && (response.body.response == 200 || response.body.response == 204)) {
            this.toastService.showToast({ header: 'Success', message: 'Project has been created' });
            this.back.emit();
            this.newProject = {};
          }
          else {
            this.toastService.showToast({ header: 'Error', message: response.body.responseDescription });
          }
        },
        (error: any) => {
          this.toastService.showToast({ header: 'Error', message: 'Oops, something went wrong creating project data' });
        }
      );

  }

  ngOnInit() {
    this.user = this.userService.getData();
    this.getCompanies();
  }

}
