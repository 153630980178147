import { Injectable } from '@angular/core';

@Injectable()
export class dataService {
  data: any;

  constructor() { }

  setData(key: any, data: any) {
    window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(
      data
    ));
    // console.log('setting' + JSON.stringify(this.data));
    this.data = data;
  }

  getData(key: any) {
    this.data = JSON.parse(window.localStorage.getItem(key));
    // console.log('fetching' + JSON.stringify(this.data));
    return this.data;
  }

  clearData(key: any) {
    window.localStorage.removeItem(key);
    // console.log('clearing' + key);
    this.data = null;
  }
}